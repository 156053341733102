import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Container,
  LeftContainer,
  RightContainer,
  StyledTypography,
  Text,
} from "./style";
import BannerLogo from "../../../../assets/team/TeamPageHeadBanner.png";

const Banner = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container display="flex" sx={{ maxWidth: "100%" }}>
        <Container>
          <LeftContainer item sm={12} md={12}>
            <Text item container xs={11} sm={12}>
              <StyledTypography sx={{ fontSize: "40px" }}>
                {t("teamPage.slogan1")}{" "}
                <StyledTypography
                  sx={{ fontSize: "40px" }}
                  variant="largeBoldText"
                >
                  {t("teamPage.slogan2")}
                </StyledTypography>{" "}
              </StyledTypography>
            </Text>
            <Text
              item
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <StyledTypography sx={{ fontSize: "40px" }}>
                {t("teamPage.slogan3")}{" "}
              </StyledTypography>
            </Text>
          </LeftContainer>

          <RightContainer item lg={12} md={12}>
            <img src={BannerLogo} alt="logo" style={{ width: "100%" }} />
          </RightContainer>
        </Container>
      </Grid>
    </>
  );
};

export default Banner;
