import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import CustomTypography from "../../../../components/CustomTypography";

export const ProductGrid = styled(Grid)`
  background-color: #edf0f6;
  width: 1516px;
  margin: 0 auto;
  height: auto;
  padding-bottom: 72px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem;
  }
`;

export const ContextGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const InnerGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-right: 2px solid #194591;
  margin: 0 auto;
  &:last-child {
    border-right: none;
  }
  &:first-child {
    padding: 0;
  }
  @media (max-width: 900px) {
    padding: 0px;
    border-right: none;
  }
`;

export const ContextTypography = styled(CustomTypography)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: black;
  max-width: 468px;
  padding: 36px 20px;
  margin-right: 20px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    max-width: 400px;
    margin-right: 20px;
    padding-bottom: 72px;
  }
  @media (max-width: 900px) {
    max-width: 100%;
    width: 100%;
    padding-bottom: 40px;
    padding-top: 16px;
  }
`;
