/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box } from "@mui/system";
import { Container, Wrapper, BlackAndWhiteImage } from "./style";
import { Typography } from "@mui/material";

function TeamMembers({ variant, title, teamData }) {
  return (
    <Wrapper>
      <Container
        sx={{ flexDirection: "row", display: "flex", flexWrap: "wrap" }}
      >
        {teamData.map((item, index) => (
          <Box>
            {item?.image === null ? (
              <BlackAndWhiteImage
                key={index}
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "white",
                  height:
                    variant === "desktop"
                      ? "252px !important"
                      : "159px !important",
                  width:
                    variant === "desktop"
                      ? "226px !important"
                      : "143px !important",
                  display: "flex !important",
                  alignItems: "flex-end",
                  marginLeft: "32px",
                }}
              >
                {item?.staff_image}
              </BlackAndWhiteImage>
            ) : (
              <BlackAndWhiteImage
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "white",
                  height:
                    variant === "desktop"
                      ? "252px !important"
                      : "159px !important",
                  width:
                    variant === "desktop"
                      ? "226px !important"
                      : "143px !important",
                  display: "flex !important",
                  alignItems: "flex-end",
                  objectFit: "contain",
                  marginLeft: "32px",
                }}
              >
                <img
                  style={{ borderRadius: "8px" }}
                  src={item?.staff_image}
                  alt="Team Photos"
                />
              </BlackAndWhiteImage>
            )}

            <Typography
              sx={{
                fontFamily: "Open Sans",
                color: "#4A4A4A",
                fontSize: variant === "desktop" ? "16px" : "12px",
                marginTop: variant === "desktop" ? "16px" : "12px",
                marginBottom: variant === "desktop" ? "12px" : "6px",
                marginLeft: "32px",
                fontWeight: "bold !important",
              }}
            >
              {item.name.toUpperCase()} {item.surname.toUpperCase()}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Open Sans",
                color: "#194591",
                fontSize: variant === "desktop" ? "14px" : "10px",
                marginBottom: variant === "desktop" ? "12px" : "6px",
                fontWeight: "bold !important",
                marginLeft: "32px",
              }}
            >
              {title}
            </Typography>
            <Box
              sx={{
                marginBottom: "48px",
                width: "226px",
              }}
            >
              {item?.school_id_name && (
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    color: "#4A4A4A",
                    fontSize: variant === "desktop" ? "12px" : "8px",
                    marginLeft: "32px",
                  }}
                >
                  {item?.school_id_name}-{item?.school_department}
                </Typography>
              )}
              {item?.doctorate_school_id_name && (
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    color: "#4A4A4A",
                    fontSize: variant === "desktop" ? "12px" : "8px",
                    marginLeft: "32px",
                  }}
                >
                  {item?.doctorate_school_id_name}-{item?.doctorate_department}
                </Typography>
              )}
              {item?.master_school_id_name && (
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    color: "#4A4A4A",
                    fontSize: variant === "desktop" ? "12px" : "8px",
                    marginLeft: "32px",
                  }}
                >
                  {item?.master_school_id_name}-{item?.master_department}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Container>
    </Wrapper>
  );
}

export default TeamMembers;
