/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box } from "@mui/system";
import Slider from "react-slick";
import { Container, Wrapper, ImageBox } from "./style";
import { Typography } from "@mui/material";

function TeamMembers({ variant, activeRole, slider, teamData }) {
  useEffect(() => {
    slider?.current?.slickGoTo(0, true);
  }, [teamData, activeRole]);

  const filteredPm = teamData.filter((item) => {
    if (
      activeRole === "" ||
      activeRole === "Tümü" ||
      activeRole === "Show All"
    ) {
      return true;
    }
    return item?.job_title === activeRole;
  });

  const settings = {
    className: "center",
    infinite: false,
    slidesToShow: 6,
    dots: false,
    arrows: false,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1516,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: filteredPm.length < 5 ? 4.5 : 5,
          dots: false,
          arrows: false,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1170,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 4,
          dots: false,
          arrows: false,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1030,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 3,
          dots: false,
          arrows: false,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 2,
          dots: false,
          arrows: false,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 2,
          dots: false,
          arrows: false,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 2.5,
          dots: false,
          arrows: false,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 2.2,
          dots: false,
          arrows: false,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 380,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 2,
          dots: false,
          arrows: false,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <Container>
        <Slider ref={slider} {...settings}>
          {teamData?.length > 0 &&
            teamData
              .filter((item) => {
                if (
                  activeRole === "" ||
                  activeRole === "Tümü" ||
                  activeRole === "Show All"
                ) {
                  return true;
                }
                return item?.job_title === activeRole;
              })
              .map((item, index) => (
                <Box key={index}>
                  {item?.image === null ? (
                    <ImageBox
                      sx={{
                        backgroundColor: "white",
                        height:
                          variant === "desktop"
                            ? "252px !important"
                            : "159px !important",
                        width:
                          variant === "desktop"
                            ? "226px !important"
                            : "143px !important",
                        display: "flex !important",
                        alignItems: "flex-end",
                        borderRadius: "0.5rem",
                        objectFit: "contain",
                      }}
                    >
                      {item?.staff_image}
                    </ImageBox>
                  ) : (
                    <ImageBox
                      sx={{
                        backgroundColor: "white",
                        height:
                          variant === "desktop"
                            ? "252px !important"
                            : "159px !important",
                        width:
                          variant === "desktop"
                            ? "226px !important"
                            : "143px !important",
                        display: "flex !important",
                        alignItems: "flex-end",
                        objectFit: "contain",
                        borderRadius: "8px",
                      }}
                    >
                      <img
                        style={{ borderRadius: "8px" }}
                        width={"100%"}
                        src={item?.staff_image}
                        alt="Team Photos"
                      />
                    </ImageBox>
                  )}
                  <Box
                    sx={{ width: variant === "desktop" ? "226px" : "143px" }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        color: "#4A4A4A",
                        fontSize: variant === "desktop" ? "16px" : "12px",
                        marginTop: variant === "desktop" ? "16px" : "12px",
                        marginBottom: variant === "desktop" ? "12px" : "6px",
                        fontWeight: "bold !important",
                      }}
                    >
                      {item?.name.toUpperCase()} {item?.surname.toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        color: "#194591",
                        fontSize: variant === "desktop" ? "14px" : "10px",
                        marginBottom: variant === "desktop" ? "12px" : "6px",
                        fontWeight: "bold !important",
                      }}
                    >
                      {item?.job_title}
                    </Typography>
                    {item?.school_id_name && (
                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                          color: "#4A4A4A",
                          fontSize: variant === "desktop" ? "12px" : "8px",
                        }}
                      >
                        {item?.school_id_name} - {item?.school_department}
                      </Typography>
                    )}

                    {item?.doctorate_school_id_name && (
                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                          color: "#4A4A4A",
                          fontSize: variant === "desktop" ? "12px" : "8px",
                        }}
                      >
                        {item?.doctorate_school_id_name}-
                        {item?.doctorate_department}
                      </Typography>
                    )}
                    {item?.master_school_id_name && (
                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                          color: "#4A4A4A",
                          fontSize: variant === "desktop" ? "12px" : "8px",
                        }}
                      >
                        {item?.master_school_id_name}-{item?.master_department}
                      </Typography>
                    )}
                  </Box>
                </Box>
              ))}
        </Slider>
      </Container>
    </Wrapper>
  );
}

export default TeamMembers;
