/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Grid, Hidden } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { TeamContainer, HeadBannerButton } from "./style";
import { ArrowRight } from "../../../../assets/icons";
import TeamMembers from "../../../../components/TeamMembers";
import TeamMembersFilterMobile from "../../../../components/TeamMembersFilterMobile";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_BASE_URL } from "../../../../constants/api";

const Team = () => {
  const { i18n, t } = useTranslation();
  const [teamData, setTeamData] = useState([]);
  const [activeFilter, setActiveFilter] = useState("");
  const slider = useRef();
  const navigate = useNavigate();

  const roles = [t("teamPage.all")];

  useEffect(() => {
    const getTeamMember = async () => {
      try {
        const response = await axios({
          url:
            API_BASE_URL +
            i18n.language +
            `/staff/list` +
            (i18n.language === "tr" ? `Tr/` : "En/"),
          method: "GET",
        });
        setTeamData(response.data);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    };
    getTeamMember();
  }, [i18n.language]);
  const uniqueTags = [];
  teamData.map((item) => {
    var findItem = uniqueTags.find((x) => x.job_title === item.job_title);
    if (!findItem) uniqueTags.push(item);
  });
  uniqueTags.map((item) => roles.push(item.job_title));
  useEffect(() => {
    setActiveFilter(roles[0]);
  }, [i18n.language]);
  return (
    <Grid container backgroundColor="#EDF0F6">
      <TeamContainer
        margin={{ xs: "2rem auto", md: "4rem auto" }}
        item
        container
      >
        <TeamMembersFilterMobile
          slider={slider}
          roles={roles}
          active={activeFilter}
          setActive={setActiveFilter}
        />
        {/* </Hidden> */}

        <Hidden smDown>
          <TeamMembers
            slider={slider}
            variant="desktop"
            activeRole={activeFilter}
            teamData={teamData}
          />
          <Box
            sx={{
              display: "flex",
              gap: "32px",
              justifyContent: "flex-end",
              marginTop: "3rem",
            }}
          >
            <HeadBannerButton
              onClick={() => {
                navigate("/career");
              }}
            >
              {t("westeropsTeam.joinUsButton")} <ArrowRight marginLeft />
            </HeadBannerButton>
            <HeadBannerButton
              onClick={() => {
                navigate("/team");
              }}
            >
              {t("westeropsTeam.seeAllTeam")} <ArrowRight marginLeft />
            </HeadBannerButton>
          </Box>
        </Hidden>

        <Hidden smUp>
          <TeamMembers
            slider={slider}
            variant="mobile"
            activeRole={activeFilter}
            teamData={teamData}
          />
          <Box
            sx={{
              display: "flex",
              gap: "12px",
              width: "100%",
              justifyContent: "center",
              marginTop: "1.5rem",
            }}
          >
            <HeadBannerButton
              onClick={() => {
                navigate("/career");
              }}
            >
              {t("westeropsTeam.joinUsButton")} <ArrowRight marginLeft />
            </HeadBannerButton>
            <HeadBannerButton
              onClick={() => {
                navigate("/team");
              }}
            >
              {t("westeropsTeam.seeAllTeam")} <ArrowRight marginLeft />
            </HeadBannerButton>
          </Box>
        </Hidden>
      </TeamContainer>
    </Grid>
  );
};

export default Team;
