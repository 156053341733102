import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ProductGrid,
  ContextGrid,
  ContextTypography,
  InnerGrid,
} from "./style";
import axios from "axios";
import { API_BASE_URL } from "../../../../constants/api";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../../../components/CustomTypography";

const Products = () => {
  const [products, setProducts] = useState([]);
  console.log("setProducts", products);
  const [page, setPage] = useState(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          url:
            API_BASE_URL +
            i18n.language +
            `/landing/product/list` +
            (i18n.language === "tr" ? `Tr/` : `En/`),
          method: "GET",
        });
        setProducts(response.data);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    };
    fetchData();
  }, [page, i18n.language]);

  return (
    <Grid
      style={{
        width: "100%",
        backgroundColor: "#EDF0F5",
      }}
    >
      <ProductGrid>
        <CustomTypography
          style={{ margin: "72px 0 56px 0", color: "#194591" }}
          variant="title"
        >
          Products
        </CustomTypography>
        <ContextGrid>
          {products.map((item, index) => (
            <InnerGrid key={index} index>
              <img
                src={item.image}
                alt="aws"
                style={{
                  width: "300px",
                  height: "66px",
                }}
              />
              <ContextTypography variant="breadCrumbContent">
                {item.description}
              </ContextTypography>
            </InnerGrid>
          ))}
        </ContextGrid>
      </ProductGrid>
    </Grid>
  );
};

export default Products;
