import { Grid, Hidden } from "@mui/material";
import React, { useRef } from "react";
import { TeamContainer, HeadBannerButton, StyledTypography } from "./style";
import { ArrowRight } from "../../../../assets/icons";
import TeamMembers from "../TeamMembers";
import TeamMembersMobile from "../TeamMembersMobile";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const TeamSlider = ({ title, noButton, lessMargin, teamslider, index }) => {
  const navigate = useNavigate();
  const slider = useRef();
  return (
    <Grid container backgroundColor="#EDF0F6">
      <TeamContainer margin={{ xs: "0rem auto 1rem", md: "1rem auto" }}>
        <StyledTypography
          margin={{ xs: "0", md: lessMargin ? "0rem 0 0 2rem" : "0 0 0 2rem" }}
          padding={{
            xs: index === 0 ? "2rem 2rem 0 2rem" : "0 2rem",
            md: "0rem",
          }}
        >
          {title}
        </StyledTypography>
        <Hidden smDown>
          <TeamMembers variant="desktop" title={title} teamData={teamslider} />
          {!noButton && (
            <Box
              sx={{
                display: "flex",
                gap: "32px",
                justifyContent: "flex-end",
                marginTop: "3rem",
                marginRight: "8rem",
              }}
            >
              <HeadBannerButton onClick={() => navigate("/career")}>
                {t("teamPage.button")} <ArrowRight marginLeft />
              </HeadBannerButton>
            </Box>
          )}
        </Hidden>

        <Hidden smUp>
          <TeamMembersMobile
            slider={slider}
            variant="mobile"
            teamData={teamslider}
            title={title}
          />
          {!noButton && (
            <Box
              sx={{
                display: "flex",
                gap: "24px",
                justifyContent: "center",
                marginTop: "1.5rem",
              }}
            >
              <HeadBannerButton onClick={() => navigate("/career")}>
                {t("teamPage.button")}
                <ArrowRight marginLeft />
              </HeadBannerButton>
            </Box>
          )}
        </Hidden>
      </TeamContainer>
    </Grid>
  );
};

export default TeamSlider;
