import { Box, Grid } from "@mui/material";
import React, { useRef } from "react";
import { ArrowLeft, ArrowRight } from "../../assets/icons";
import {
  Container,
  GridContainer,
  LeftContainer,
  RightContainer,
  StyledButton,
  Text,
  Wrapper,
  StyledTypography,
} from "./style";
import CustomTypography from "../../components/CustomTypography";
import Slider from "react-slick";

const PortfolioSliderMainPage = ({ portfolio, background }) => {
  const slider = useRef();
  const handleArrowLeft = () => {
    slider.current.slickPrev();
  };
  const handleArrowRight = () => {
    slider.current.slickNext();
  };
  const settings = {
    className: "center",
    infinite: true,
    slidesToShow: 1,
    dots: true,
    dotsClass: "slick-dots",
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 390,
        settings: {
          className: "center",
          infinite: true,
          slidesToShow: 1,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Grid container backgroundColor={background}>
      <Wrapper>
        <Container item>
          <Slider ref={slider} {...settings}>
            {portfolio.map((_item, index) => (
              <GridContainer
                item
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                key={index}
              >
                <LeftContainer
                  item
                  marginLeft={{ xs: "1rem", sm: "0", md: "1rem" }}
                  marginTop={{ xs: "-4rem" }}
                >
                  <Grid marginLeft={{ xs: "-0.25rem" }}>
                    <img
                      id="logo"
                      src={_item.Designs[0].design_logo}
                      alt="logo"
                    />
                  </Grid>
                  <Text item xs={8}>
                    <CustomTypography
                      variant="largeText"
                      color="#194591"
                      style={{ paddingTop: "1rem" }}
                    >
                      <strong>{_item.bold} </strong>
                      {_item.name}
                    </CustomTypography>
                  </Text>
                  <Grid
                    container
                    marginLeft={{ xs: "-0.25rem" }}
                    /* style={{ paddingBottom: "rem" }} */
                  >
                    {_item.Designs[0].Tags.map((tag, index) => (
                      <StyledButton key={index} variant="standard" disabled>
                        {tag.name}
                      </StyledButton>
                    ))}
                  </Grid>
                  <Text item xs={12} md={12}>
                    <CustomTypography
                      variant="smallText"
                      color="#4a4a4a !important"
                      lineHeight="32px"
                    >
                      {_item.Designs[0].content}
                    </CustomTypography>
                  </Text>
                </LeftContainer>
                <RightContainer>
                  <img
                    src={_item.Designs[0].design_image}
                    alt="logo"
                    id="picture"
                  />
                </RightContainer>
              </GridContainer>
            ))}
          </Slider>
          <Box className="arrows" sx={{ float: "right" }}>
            <ArrowLeft onClick={handleArrowLeft} color="#194591" />
            <ArrowRight
              onClick={handleArrowRight}
              color="#194591"
              marginLeft="20px"
            />
          </Box>
        </Container>
      </Wrapper>
    </Grid>
  );
};

export default PortfolioSliderMainPage;
