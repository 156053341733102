/* eslint-disable array-callback-return */
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Banner from "./components/Banner";
import TeamSlider from "./components/TeamSlider";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_BASE_URL } from "../../constants/api";
import { motion, AnimatePresence } from "framer-motion";
import Loading from "../../components/Loading";

const Team = () => {
  const { i18n } = useTranslation();
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    const getTeamMember = async () => {
      try {
        const response = await axios({
          url:
            API_BASE_URL +
            i18n.language +
            `/staff/${i18n.language === "tr" ? `listTr/` : "listEn/"}`,
          method: "GET",
        });
        setTeamData(response.data);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    };
    getTeamMember();
  }, [i18n.language]);

  const roles = [];
  const uniqueTags = [];
  teamData?.map((item) => {
    var findItem = uniqueTags.find((x) => x.job_title === item.job_title);
    if (!findItem) uniqueTags.push(item);
  });
  uniqueTags.map((item) => roles.push(item.job_title));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid
      container
      sx={{
        margin: "6rem auto 0 auto",
      }}
    >
      <AnimatePresence>
        {!teamData.length && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              width: "1000vw",
              height: "1000vw",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: "999999999",
            }}
          >
            <Loading />
          </motion.div>
        )}
      </AnimatePresence>
      <Banner />
      {roles?.map((item, index) => (
        <TeamSlider
          key={index}
          index={index}
          title={item}
          noButton
          lessMargin
          teamslider={teamData?.filter(
            (filterItem) => filterItem.job_title === item
          )}
        />
      ))}
    </Grid>
  );
};

export default Team;
