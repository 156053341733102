import "./App.css";
import React, { Suspense } from "react";
import { ThemeProvider } from "styled-components";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./helpers/i18-init";
import Header from "./components/Header";
import theme from "./theme";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import MainPage from "./pages/MainPage";
import Blog from "./pages/Blogs/Blog";
import BlogDetail from "./pages/Blogs/BlogDetail";
import Career from "./pages/Careers/Career";
import CareerDetails from "./pages/Careers/CareerDetails";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import About from "./pages/About";
import TechPartnersPage from "./pages/TechPartnersPage";
import Team from "./pages/Team";
import GetOffer from "./pages/GetOffer";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>
          WesterOps | DevOps - Web & Mobile App Development - Data Analytics -
          Startup Enablement
        </title>
        <meta
          name="description"
          content="DevOps, Middleware, Cloud | Digital Predator or Digital Prey Which one do you want to be? "
        />
      </Helmet>
      <Header />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/post/:id/:title" element={<BlogDetail />} />
          <Route path="/career" element={<Career />} />
          <Route path="/career/post/:jobId" element={<CareerDetails />} />
          <Route path="/tech_partners" element={<TechPartnersPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/getoffer" element={<GetOffer />} />
        </Routes>
      </Suspense>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
