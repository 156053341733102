/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box } from "@mui/system";
import Slider from "react-slick";
import { Container, Wrapper, BlackAndWhiteImage } from "./style";
import { Typography } from "@mui/material";

function TeamMembersMobile({ variant, slider, title, teamData }) {
  const settings = {
    className: "center",
    infinite: false,
    slidesToShow: 6,
    dots: false,

    arrows: false,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 2,
          dots: false,

          arrows: false,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 3,
          dots: false,

          arrows: false,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 630,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 2,
          dots: false,

          arrows: false,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 770,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 2.5,
          dots: false,

          arrows: false,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1030,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 3,
          dots: false,

          arrows: false,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1260,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 4,
          dots: false,

          arrows: false,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1575,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 5,
          dots: false,

          arrows: false,
          slidesToScroll: 5,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper>
      <Container>
        <Slider ref={slider} {...settings}>
          {teamData.map((item, index) => (
            <Box>
              {item?.staff_mobil_image === null ? (
                <BlackAndWhiteImage
                  key={index}
                  sx={{
                    backgroundColor: "white",
                    height:
                      variant === "desktop"
                        ? "159px !important"
                        : "159px !important",
                    width:
                      variant === "desktop"
                        ? "143px !important"
                        : "143px !important",
                    display: "flex !important",
                    alignItems: "flex-end",
                  }}
                >
                  {item?.staff_mobil_image}
                </BlackAndWhiteImage>
              ) : (
                <BlackAndWhiteImage>
                  <img
                    width="143"
                    height="159"
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "white",
                      display: "flex !important",
                      alignItems: "flex-end",
                    }}
                    src={item?.staff_mobil_image}
                    alt="Team Photos"
                  />
                </BlackAndWhiteImage>
              )}
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  color: "#4A4A4A",
                  fontSize: variant === "desktop" ? "16px" : "12px",
                  marginTop: variant === "desktop" ? "16px" : "12px",
                  marginBottom: variant === "desktop" ? "12px" : "6px",
                  fontWeight: "bold !important",
                }}
              >
                {item.name.toUpperCase()} {item.surname.toUpperCase()}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  color: "#194591",
                  fontSize: variant === "desktop" ? "14px" : "10px",
                  marginBottom: variant === "desktop" ? "12px" : "6px",
                  fontWeight: "bold !important",
                }}
              >
                {title}
              </Typography>
              <Box
                sx={{
                  marginBottom: "24px",
                  width: "143px",
                }}
              >
                {item?.school_department && (
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      color: "#4A4A4A",
                      fontSize: variant === "desktop" ? "12px" : "8px",
                    }}
                  >
                    {item?.school_department}
                  </Typography>
                )}
                {item?.doctorate_department && (
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      color: "#4A4A4A",
                      fontSize: variant === "desktop" ? "12px" : "8px",
                    }}
                  >
                    {item?.doctorate_department}
                  </Typography>
                )}
                {item?.master_department && (
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      color: "#4A4A4A",
                      fontSize: variant === "desktop" ? "12px" : "8px",
                    }}
                  >
                    {item?.master_department}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
        </Slider>
      </Container>
    </Wrapper>
  );
}

export default TeamMembersMobile;
